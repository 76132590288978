import {
  FluentModalTall,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import { Checkbox, PrimaryButton } from "@fluentui/react";

import { OutputPreview } from "../OutputPreview";

import "./AdjustChartElementsDialog.scss"; // Assuming you'll have corresponding SCSS
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { defined } from "../../../../../lib/core/defined";

interface Props {
  cardId: string;
  onClose: () => void;
  settings: DataOutputSettings;
  setSettings: (settings: DataOutputSettings) => void;
}

export function AdjustChartElementsDialog(props: Props) {
  const { onClose, settings, setSettings } = props;

  const handleOutputSettingChange = <K extends keyof DataOutputSettings>(
    setting: K,
    value: boolean
  ) => {
    setSettings({ ...settings, [setting]: value });
  };

  return (
    <FluentModalTall
      title="Ändra diagramelement"
      isOpen={true}
      onClose={onClose}
      width="md"
    >
      <FluentModalBody>
        <div id="adjust-chart-elements-dialog">
          <h3>Allmänt</h3>
          <section className="settings-grid">
            <Checkbox
              label="Visa dataetiketter"
              checked={settings.showLabels}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showLabels", !!checked)
              }
            />
            <Checkbox
              label="Visa diagramtitel"
              checked={!settings.hideChartTitleSection}
              onChange={(ev, checked) =>
                handleOutputSettingChange("hideChartTitleSection", !checked)
              }
            />
            <Checkbox
              label="Visa kategorirubriker"
              checked={!settings.hideLegendDimensionLabels}
              onChange={(ev, checked) =>
                handleOutputSettingChange("hideLegendDimensionLabels", !checked)
              }
            />
          </section>
          <h3>Linjediagram</h3>
          <section className="settings-grid">
            <Checkbox
              label="Visa punkter i linjediagram"
              checked={settings.showLineCircles}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showLineCircles", !!checked)
              }
            />
            <Checkbox
              label="Markera sista punkten i varje serie"
              checked={settings.lineChartAccentuateLastDatum}
              onChange={(ev, checked) =>
                handleOutputSettingChange(
                  "lineChartAccentuateLastDatum",
                  !!checked
                )
              }
            />
            <Checkbox
              label="Visa etikett för sista punkten i varje serie (till vänster)"
              checked={settings.lineChartShowLastLabel}
              onChange={(ev, checked) => {
                if (!defined(checked)) {
                  return;
                }
                if (checked) {
                  return setSettings({
                    ...settings,
                    lineChartShowLastLabelAlignLeft: false,
                    lineChartShowLastLabel: checked,
                  });
                }
                handleOutputSettingChange("lineChartShowLastLabel", checked);
              }}
            />
            <Checkbox
              label="Visa etikett för sista punkten i varje serie (till höger)"
              checked={settings.lineChartShowLastLabelAlignLeft}
              onChange={(ev, checked) => {
                if (!defined(checked)) {
                  return;
                }
                if (checked) {
                  return setSettings({
                    ...settings,
                    lineChartShowLastLabel: false,
                    lineChartShowLastLabelAlignLeft: checked,
                  });
                }
                handleOutputSettingChange(
                  "lineChartShowLastLabelAlignLeft",
                  checked
                );
              }}
            />
            <Checkbox
              label="Visa feta linjer i linjediagram"
              checked={settings.showFatLines}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showFatLines", !!checked)
              }
            />
          </section>
          <h3>Stapeldiagram</h3>
          <section className="settings-grid">
            <Checkbox
              label="Täta liggande staplar"
              checked={settings.denseHorizontalBarsMode}
              onChange={(ev, checked) =>
                handleOutputSettingChange("denseHorizontalBarsMode", !!checked)
              }
            />
          </section>

          <h3>Axlar och skalstreck</h3>
          <section className="settings-grid">
            <Checkbox
              label="Visa y-axel"
              checked={settings.showYAxis}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showYAxis", !!checked)
              }
            />
            <Checkbox
              label="Visa x-axel"
              checked={settings.showXAxis}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showXAxis", !!checked)
              }
            />
            <Checkbox
              label="Visa skalstreck längs y-axel"
              checked={settings.showTicksYAxis}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showTicksYAxis", !!checked)
              }
            />
            <Checkbox
              label="Visa skalstreck längs x-axel"
              checked={settings.showTicksXAxis}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showTicksXAxis", !!checked)
              }
            />
            <Checkbox
              label="Visa y-axelvärden"
              checked={settings.showYAxisLabels}
              onChange={(ev, checked) =>
                handleOutputSettingChange("showYAxisLabels", !!checked)
              }
            />
            <Checkbox
              label="Tvinga dataetiketter att hamna utanför staplar"
              checked={settings.forceLabelsOutsideBars}
              onChange={(ev, checked) =>
                handleOutputSettingChange("forceLabelsOutsideBars", !!checked)
              }
            />
          </section>

          <div className="preview">
            <h2>Förhandsvisning</h2>
            <OutputPreview cardId={props.cardId}></OutputPreview>
          </div>
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <PrimaryButton onClick={onClose}>Stäng</PrimaryButton>
      </FluentModalFooter>
    </FluentModalTall>
  );
}
