import React from "react";

import { config } from "../config";
import { ExternalLink } from "./ExternalLink";
import "./ErrorBoundary.scss";
import { defined } from "../lib/core/defined";
import { IconButton } from "@fluentui/react";
import { logger } from "../lib/infra/logging";

interface Props {
  onDismiss?: () => void;
}

export function ErrorBoundaryFallback(props: Props) {
  const dismiss = props.onDismiss;
  return (
    <div className="error-boundary-container">
      <div className="header-block">
        <h2>Ett oväntat fel inträffade.</h2>
        {defined(dismiss) && (
          <IconButton
            onClick={() => dismiss()}
            iconProps={{ iconName: "cross" }}
          ></IconButton>
        )}
      </div>
      <p>
        Felet har rapporterats till administratören.{" "}
        <ExternalLink
          link={config.infostatSeLinks.contact}
          text="Se här"
        ></ExternalLink>{" "}
        för kontaktuppgifter.
      </p>
    </div>
  );
}

interface CustomErrorBoundaryProps {
  children: JSX.Element;
  onDismiss?: () => void;
}

interface CustomErrorBoundaryState {
  hasError: boolean;
}

/**
 * A custom React error boundary that shows a fallback UI and logs errors to the console.
 */
export class CustomErrorBoundary extends React.Component<
  CustomErrorBoundaryProps,
  CustomErrorBoundaryState
> {
  constructor(props: CustomErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error("Error captured by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFallback onDismiss={this.props.onDismiss} />;
    }

    return this.props.children;
  }
}
