import { useContext, useEffect, useRef } from "react";

import { TabbedCard } from "../../../../components/Card";
import { CustomErrorBoundary } from "../../../../components/ErrorBoundary";
import { ISharingInfo } from "../../../../lib/application/files/SharingInfo";
import { useCardEditMode } from "../../../../lib/application/state/stats/useEditMode";
import { classNames } from "../../../../lib/core/classNames";

import "./CardContainer.scss";
import {
  handleCardScrolledTo,
  shouldCardScrollIntoView,
} from "../../../../lib/application/stats/document_position";
import { defined } from "../../../../lib/core/defined";
import {
  CardBgColorContext,
  HideSpaceAfterCardContext,
  HideSpaceBeforeCardContext,
} from "../../../../lib/application/contexts";
import { useCardBgColor } from "../../../../lib/application/state/stats/useCardBgColor";

interface Props {
  children: JSX.Element[];
  cardId: string;
  disableScrollIntoView?: boolean;
  className?: string;
  /** For removal of a card that has crashed */
  removeBrokenCard: (id: string) => void;
  /** For animation */
  isRemovingCard: boolean;
  sharingInfo: ISharingInfo;
}
export function CardContainer(props: Props) {
  const { cardId } = props;
  const cardRef = useRef<HTMLDivElement>(null);
  const bgColor = useCardBgColor(cardId);
  const {
    isEditingCard: isEditing,
    hasEditDocumentAccess,
    isEditingDocument,
  } = useCardEditMode(cardId, props.sharingInfo);

  const hideSpaceBefore = useContext(HideSpaceBeforeCardContext);
  const hideSpaceAfter = useContext(HideSpaceAfterCardContext);

  useEffect(() => {
    if (props.disableScrollIntoView) {
      return;
    }

    const handle = setTimeout(() => {
      if (!shouldCardScrollIntoView(cardId)) {
        return;
      }
      if (!defined(cardRef.current)) {
        return;
      }
      handleCardScrolledTo();
      cardRef.current.scrollIntoView({ behavior: "smooth" });
    }, 300);
    return () => {
      clearTimeout(handle);
    };
  }, [cardId, props.disableScrollIntoView]);

  return (
    <CustomErrorBoundary onDismiss={() => props.removeBrokenCard(cardId)}>
      <CardBgColorContext.Provider value={bgColor}>
        <TabbedCard
          cardRef={cardRef}
          hideTab={!hasEditDocumentAccess || !isEditingDocument}
          className={classNames(
            hideSpaceAfter ? "hide-space-after" : "",
            hideSpaceBefore ? "hide-space-before" : "",
            "card-id-" + cardId,
            "document-card-container",
            isEditingDocument ? "editing-document" : "",
            isEditing ? "editing" : "read-only",
            hasEditDocumentAccess ? "edit-mode-enabled" : "",
            props.isRemovingCard ? "is-removing" : undefined,
            props.className
          )}
        >
          {props.children}
        </TabbedCard>
      </CardBgColorContext.Provider>
    </CustomErrorBoundary>
  );
}
