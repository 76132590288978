import * as d3scl from "d3-scale";
import * as _ from "lodash";

import { defined } from "../../../../core/defined";
import { APPROX_MAX_BAR_WIDTH } from "../charts_common";

export type BandScalesByDimension = {
  scale: d3scl.ScaleBand<string>;
  dimension: string;
}[];

interface DimensionWithLabels {
  dimension: string;
  labels: string[];
}

/**
 * Conceptually nested band scales.
 * _________
 * | M  | A |
 * | a  |---
 * | n  | B |
 * |    |---
 * |    | C |
 * ---------
 * | K  | A |
 * | vi |---
 * | nn | B |
 * | a  |---
 * |    | C |
 * ----------
 * | (R | R |
 * | e  | e |
 * | f) | f |
 * |    |   |
 * |    |   |
 * ----------
 *
 * -
 */
export function makeBandScales(
  layersOriginal: readonly DimensionWithLabels[],
  legendDimension: DimensionWithLabels | undefined,
  /**
   * The max pixel width/height in domain dimension
   */
  domainPixelRangeMax: number,
  isTallChart: boolean,
  totalNumBars: number,
  /** Thin bars and labels, for dense charts */
  denseHorizontalBars: boolean,
  customBarMaxWidth?: number
): BandScalesByDimension {
  const layers = layersOriginal.slice();
  const bandScales: BandScalesByDimension = [];

  const approxBarWidth = defined(customBarMaxWidth)
    ? customBarMaxWidth
    : APPROX_MAX_BAR_WIDTH;

  const getBandPaddingInner = (
    pixelRange: number,
    numBars: number,
    paddingOuter: number
  ) =>
    findBandPaddingInner(
      pixelRange,
      numBars,
      customBarMaxWidth ?? 30,
      paddingOuter,
      false,
      denseHorizontalBars
    );

  if (totalNumBars === 1) {
    const paddingOuter = 1;
    const paddingInner = getBandPaddingInner(
      domainPixelRangeMax,
      totalNumBars,
      paddingOuter
    );
    const layer = layers[0];
    return [
      {
        dimension: layer?.dimension ?? ("" as any),
        scale: d3scl
          .scaleBand()
          .domain(layer?.labels ?? [""])
          .rangeRound([0, domainPixelRangeMax])
          .paddingInner(paddingInner)
          .paddingOuter(paddingOuter)
          .align(0.5),
      },
    ];
  }

  const paddingsOuter = getOuterPaddings(
    totalNumBars,
    layers,
    legendDimension,
    isTallChart,
    denseHorizontalBars
  );

  if (defined(legendDimension)) {
    layers.push(legendDimension);
  }

  for (const layer of layers) {
    const dimension = layer.dimension;
    const labels = layer.labels;
    const index = layers.indexOf(layer);
    const isInnermostLayer = layer === layers[layers.length - 1];

    const nextBandLabels = layers[index + 1]?.labels ?? [];
    const isExtremeBand = nextBandLabels.length > 10;

    const outerPadding = paddingsOuter[index];
    if (layer === layers[0]) {
      const paddingInner = Math.max(
        0.1,
        findBandPaddingInner(
          domainPixelRangeMax,
          labels.length,
          isInnermostLayer
            ? approxBarWidth
            : domainPixelRangeMax / labels.length,
          outerPadding,
          isExtremeBand,
          denseHorizontalBars
        )
      );
      bandScales.push({
        dimension,
        scale: d3scl
          .scaleBand()
          .domain(labels)
          .rangeRound([0, domainPixelRangeMax])
          .paddingInner(paddingInner)
          .paddingOuter(outerPadding),
      });
    } else {
      const prev = _.last(bandScales)!;
      const pixelRange = prev.scale.bandwidth();

      const paddingInner = findBandPaddingInner(
        domainPixelRangeMax,
        labels.length,
        domainPixelRangeMax / labels.length,
        outerPadding,
        isExtremeBand,
        denseHorizontalBars
      );
      let innerMostLevelPadding =
        pixelRange < approxBarWidth * 2
          ? 0.2
          : Math.max(
              findBandPaddingInner(
                pixelRange,
                labels.length,
                approxBarWidth,
                outerPadding,
                isExtremeBand,
                denseHorizontalBars
              ),
              0.2
            );
      if (denseHorizontalBars) {
        innerMostLevelPadding = 0.1;
      }
      const currentPaddingInner =
        layer === layers[layers.length - 1]
          ? Math.max(innerMostLevelPadding, 0.0)
          : paddingInner;
      bandScales.push({
        dimension,
        scale: d3scl
          .scaleBand()
          .domain(labels)
          .rangeRound([0, pixelRange])
          .paddingInner(currentPaddingInner)
          .paddingOuter(outerPadding),
      });
    }
  }
  return bandScales;
}

function getOuterPaddings(
  numTotalBars: number,
  dimensionsWithLabels: DimensionWithLabels[],
  legendDimension: DimensionWithLabels | undefined,
  isTallChart: boolean,
  denseHorizontalBars: boolean
): number[] {
  const layersWithLegend = dimensionsWithLabels.concat(
    defined(legendDimension) ? [legendDimension] : []
  );

  const numLayers = layersWithLegend.length;
  const paddings: number[] = layersWithLegend.map((layer, layerIndex) => {
    // Determine outerpadding based approximately on the size of the current band
    // in relation to the previous band
    const sizeFactor =
      1 / (layersWithLegend[layerIndex - 1]?.labels.length ?? 1);

    if (numLayers === 1) {
      switch (numTotalBars) {
        case 1:
        case 2:
        case 3:
          return 1;
        case 4:
          return 0.7;
        case 5:
          return 0.5;
        default:
          return 0.4;
      }
    }

    const nextBandLabels = layersWithLegend[layerIndex + 1]?.labels;
    const isExtremeBand = defined(nextBandLabels) && nextBandLabels.length > 10;

    if (isExtremeBand) {
      return 0;
    }

    if (layerIndex === 0) {
      if (numTotalBars < 5) {
        return 0.5;
      } else if (isTallChart) {
        return 0;
      }
      return 0.2;
    } else {
      if (isTallChart) {
        if (numTotalBars > 5) {
          if (denseHorizontalBars) {
            return 0.1;
          }
          return 0.2;
        }
        return 0.5;
      }
      if (numTotalBars < 5) {
        return Math.min(0.2 / sizeFactor, 0.5);
      }
      return Math.min(0.1 / sizeFactor, 0.5);
    }
  });

  return paddings;
}

function findBandPaddingInner(
  totalWidth: number,
  numBars: number,
  barMaxWidth: number,
  outerPadding: number,
  isExtremeBand: boolean,
  denseHorizontalBarsMode: boolean
): number {
  if (isExtremeBand) {
    return 0;
  }

  const maxPadding = totalWidth > 30 && denseHorizontalBarsMode ? 0.1 : 1;

  // n = numBars
  // b = bandwidth
  // barMax = max allowed width of bar
  // pI = paddingInner
  // pO = paddingOuter
  // tot = totalWidth

  // Simultaneous equations
  // (1) tot = 2 * pO * b + pI * b * Max(1,n-1) + (1 - pI)b * n
  // (2) b * (1-pI) = barMax
  // b = barMax / (1-pI)

  const numerator = -totalWidth + barMaxWidth * (2 * outerPadding + numBars);
  const denom =
    barMaxWidth * numBars - totalWidth - barMaxWidth * Math.max(1, numBars - 1);
  const pI = numerator / denom;
  if (pI < 0) {
    return 0;
  }

  const paddingBefore = Math.max(0.2, Math.min(pI, 1)); // There must be at least a little bit of padding between bars
  if (denseHorizontalBarsMode) {
    return Math.min(maxPadding, paddingBefore);
  }

  return paddingBefore;
}
