import * as _ from "lodash";
import { defined } from "../../../../../core/defined";

import { TextStyle } from "../../core/TextStyle";
import { MultilineText } from "../../core/text_containers";
import { TextSplittingMode } from "../../core/text_splitting";
import {
  LabelLayer,
  LabelLayerWithRenderInfo,
  LayeredLabels,
  RenderInfoPublic,
  SplittingMethodInternal,
  TextContainer,
} from "./definitions";

interface LabelLayerWithRenderInfoInternal<LabelType>
  extends LabelLayerWithRenderInfo<LabelType> {
  renderInfo: RenderInfoInternal<LabelType>;
}

/**
 */
export class LayeredLabelsHorizontalBars<LabelType = string>
  implements LayeredLabels<LabelType>
{
  private _extendedLayers: LabelLayerWithRenderInfoInternal<LabelType>[];
  private _totalLayerWidthHorizontal: number;
  /**
   * @param labelLayers layers of labels, ordered from outer to inner
   */
  private constructor(
    extendedLayers: LabelLayerWithRenderInfoInternal<LabelType>[]
  ) {
    this._extendedLayers = extendedLayers;
    this._totalLayerWidthHorizontal =
      _.sum(extendedLayers.map((l) => l.renderInfo.width)) ?? 0;
  }

  static fromLabelLayers(
    labelLayers: LabelLayer[],
    textStyle: TextStyle,
    totalMaxWidth: number,
    options?: {
      labelAnchorPadding?: number;
      denseHorizontalBarsMode?: boolean;
    }
  ) {
    const averageLayerWidth = Math.ceil(totalMaxWidth / labelLayers.length);
    const extendedLayers: LabelLayerWithRenderInfoInternal<string>[] =
      labelLayers.map((layer) => {
        return {
          layer,
          renderInfo: new RenderInfoInternal(
            layer.formattedLabels.map((label) => ({
              label,
              container: new MultilineText(label, textStyle, {
                desiredMaxWidth: averageLayerWidth,
                anchorPadding: options?.labelAnchorPadding,
                anchor:
                  layer !== labelLayers[labelLayers.length - 1]
                    ? "middle"
                    : "end",
                forceSingleLineAndTruncate: options?.denseHorizontalBarsMode,
              }),
            })),
            {
              mode: TextSplittingMode.breakOnWhitespace,
              retry: true,
            }
          ),
        };
      });

    fitLabelsMutable(extendedLayers, totalMaxWidth);
    return new LayeredLabelsHorizontalBars(extendedLayers);
  }

  get totalWidth(): number {
    return this._totalLayerWidthHorizontal;
  }

  get totalHeight(): number | undefined {
    return undefined;
  }

  get numLayers(): number {
    return this._extendedLayers.length;
  }

  allLabelsMaxHeight(): number {
    const textContainers = _.flatMap(
      this._extendedLayers,
      (layer) => layer.renderInfo.textContainers ?? []
    );

    return (
      _.maxBy(textContainers, (label) => label.container.height)?.container
        .height ?? 0
    );
  }

  innermostLabelMaxHeight(): number {
    const textContainers =
      this._extendedLayers[this._extendedLayers.length - 1]?.renderInfo
        .textContainers ?? [];

    return (
      _.maxBy(textContainers, (label) => label.container.height)?.container
        .height ?? 0
    );
  }

  layers(): LabelLayer<LabelType>[] {
    return this._extendedLayers.map((l) => l.layer);
  }

  extendedLayers(): readonly LabelLayerWithRenderInfo<LabelType>[] {
    return this._extendedLayers;
  }
}

/**
 * Render information used by LayeredLabels class only
 */
class RenderInfoInternal<LabelType> implements RenderInfoPublic<LabelType> {
  constructor(
    private _textContainers: TextContainer<LabelType>[],
    private _splittingMethod: SplittingMethodInternal
  ) {}
  get textContainers(): TextContainer<LabelType>[] {
    return this._textContainers;
  }

  get method(): SplittingMethodInternal {
    return this._splittingMethod;
  }

  set method(method: SplittingMethodInternal) {
    this._splittingMethod = method;
  }

  get width(): number {
    return _.max(this._textContainers.map((t) => t.container.maxWidth)) ?? 0;
  }

  /**
   * @returns whether any update has been made
   */
  refineSplitNaive(desiredWidth: number): boolean {
    let allUnchanged = true;
    for (const label of this._textContainers) {
      const maxWidthBefore = label.container.maxWidth;
      const maxWidthAfter = label.container.refineSplitNaive(desiredWidth);
      if (maxWidthAfter < maxWidthBefore) {
        allUnchanged = false;
      }
    }
    return allUnchanged;
  }

  refineSplitForce(desiredWidth: number) {
    for (const label of this._textContainers) {
      label.container.refineSplitForce(desiredWidth);
      this._splittingMethod.retry = false;
    }
  }
}

/**
 * Ensure text containers fit into specified totalMaxWidth
 * Relies on iterative algorithm to successively shrink labels.
 */
function fitLabelsMutable(
  extendedLayers: LabelLayerWithRenderInfoInternal<string>[],
  totalMaxWidth: number
) {
  let numIterations = 0;
  const averageMaxWidth = Math.ceil(totalMaxWidth / extendedLayers.length);

  let anyLayerUpdated = true;
  while (
    sumLayersWidth(extendedLayers) > totalMaxWidth &&
    numIterations < 10 &&
    anyLayerUpdated
  ) {
    anyLayerUpdated = false;
    numIterations += 1;

    // Go through all layers.
    // First attempt to split on whitespace.
    // If already splitted on whitespace, split on anything
    for (let i = 0; i < extendedLayers.length; i++) {
      const layer = extendedLayers[i];
      const otherLayersWidth = sumLayersWidth(
        extendedLayers,
        averageMaxWidth,
        layer
      );
      const availableWidth = totalMaxWidth - otherLayersWidth;

      const method = layer.renderInfo.method;

      // Whitespace first. If no successful splits, update splitting method
      if (method.mode === TextSplittingMode.breakOnWhitespace && method.retry) {
        const allUnchanged = layer.renderInfo.refineSplitNaive(availableWidth);
        if (allUnchanged) {
          layer.renderInfo.method = {
            mode: TextSplittingMode.breakAnywhere,
            retry: true,
          };
        }
        anyLayerUpdated = true;
      } else {
        if (!method.retry) {
          continue;
        }
        layer.renderInfo.refineSplitForce(availableWidth);
        layer.renderInfo.method.retry = false;
        anyLayerUpdated = true;
      }
    }
  }
}

function sumLayersWidth<T>(
  layers: LabelLayerWithRenderInfo<T>[],
  shrinkLayerWidth?: number,
  exclude?: LabelLayerWithRenderInfo<T>
): number {
  return _.sum(
    layers.map((layer) => {
      if (layer.layer.dimension === exclude?.layer.dimension) {
        return 0;
      }
      if (!defined(shrinkLayerWidth)) {
        return layer.renderInfo.width;
      }
      return Math.min(layer.renderInfo.width, shrinkLayerWidth);
    })
  );
}
