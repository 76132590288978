const popupSize = "width=600,height=600";

export function shareImageUrlToTwitter(imageUrl: string, text: string) {
  // Encode the text and the image URL to ensure it's a valid URL format
  const encodedText = encodeURIComponent(text);
  const encodedImageUrl = encodeURIComponent(imageUrl);

  // Create the Twitter share URL with the encoded text and image URL
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodedText}${encodedImageUrl}`;

  // Open the Twitter share URL in a new window/tab
  window.open(twitterShareUrl, "Dela på Twitter", popupSize);
}

export function shareToLinkedIn(imageUrl: string) {
  const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    imageUrl
  )}`;
  window.open(url, "Dela på LinkedIn", popupSize);
}

export function shareToFacebook(imageUrl: string) {
  const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    imageUrl
  )}`;
  window.open(url, "Dela på Facebook", popupSize);
}

export function shareToBluesky(imageUrl: string) {
  const url = `https://bsky.app/intent/compose?text=${encodeURIComponent(
    imageUrl
  )}`;
  window.open(url, "Dela på Bluesky", popupSize);
}
