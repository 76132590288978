import _, { last, uniq } from "lodash";
import * as d3scl from "d3-scale";

import { defined } from "../../../core/defined";
import {
  BarChartHorizontalDataV2,
  ChartDataContainerV2BarHorizontal,
} from "../../state/stats/document-core/_core-shared";
import {
  BandScalesByDimension,
  makeBandScales,
} from "../shared/bar_chart/bandScales";
import {
  calculateNumberOfBarsFromLayers,
  calculateLegend,
  labelSpecToTextContainerHorizontalBars,
  getRangeAxisOffset,
  calcBarChartColorConfig,
} from "../shared/bar_chart/bar_chart_common";
import { calculateGroupedBarsSpec } from "../shared/bar_chart/calculateGroupedBarsSpec";
import { calculateGroupedLabels } from "../shared/bar_chart/calculateGroupedLabels";
import { LayeredLabelsHorizontalBars } from "../shared/bar_chart/labels/LayeredLabelsHorizontalBars";
import { ChartDataUnknown } from "../shared/chart_data/ChartData";
import {
  calculateBarChartHorizontalWidths,
  calculateBarChartHorizontalDimensions,
} from "../shared/charts_common";
import {
  getDefaultTextStyle,
  defaultDimensionLabelTextStyle,
} from "../shared/core/TextStyle";
import {
  Orientation,
  Dimension,
  GOLDEN_RATIO,
  ChartType,
} from "../shared/core/definitions";
import { MultilineText } from "../shared/core/text_containers";
import {
  defaultTicksStyle,
  TickLabelWithRawValue,
  TicksStyle,
  XTicksContainer,
} from "../shared/core/ticks";
import { fixedNonPrimaryDimensionsValid } from "../shared/dimensions";
import {
  GROUPED_LABELS_HORIZONTAL_BARS_PADDING_RIGHT,
  calculateLabelAreasBarChartHorizontal,
} from "../shared/labels";
import { ColorSchemeContainer } from "../../state/stats/document-style/definitions";
import {
  colorSchemesEqual,
  copyColorSchemeContainer,
  createColorSchemeContainerWithPalette,
  getSingleColor,
  makeFullColorScheme,
} from "../../state/stats/document-style/operations";
import {
  CustomThemeSpecApplied,
  defaultThemeSpec,
} from "../shared/core/colors/colorSchemes";
import { LabelLayerWithRenderInfo } from "../shared/bar_chart/labels/definitions";

const NARROW_FORMAT_MIN_BAR_PIXELS = 10;
const MIN_PIXELS_PER_BAR_DEFAULT = 20;

export function createBarChartHorizontalDataAndColors<
  T extends ChartDataUnknown
>(
  chartData: T,
  vizAreaWidth: number,
  defaultCustomColors: CustomThemeSpecApplied = defaultThemeSpec()
): ChartDataContainerV2BarHorizontal {
  const data = createBarChartHorizontalData(chartData, vizAreaWidth);
  return {
    chartType: ChartType.barHorizontal,
    renderWidth: vizAreaWidth,
    data,
    colorSchemeContainer: createColorSchemeContainer(data, defaultCustomColors),
  };
}

export function createBarChartHorizontalDataWithExistingColors<
  T extends ChartDataUnknown
>(
  chartData: T,
  vizAreaWidth: number,
  existingColors: ColorSchemeContainer
): ChartDataContainerV2BarHorizontal {
  const data = createBarChartHorizontalData(chartData, vizAreaWidth);
  const existingColorsCopy = copyColorSchemeContainer(existingColors);
  const colorKeys = data.barsSpec.uniqueColorKeys;
  for (const key of Object.keys(existingColorsCopy.colorScheme)) {
    if (!colorKeys.includes(key)) {
      delete existingColorsCopy.colorScheme[key];
    }
  }
  const scheme = makeFullColorScheme(
    existingColorsCopy,
    colorKeys,
    data.colorConfig.defaultToSingleColor,
    data.colorConfig.colorableDimensionLabel,
    getSingleColor(existingColors)
  );
  return {
    chartType: ChartType.barHorizontal,
    data,
    renderWidth: vizAreaWidth,
    colorSchemeContainer: colorSchemesEqual(existingColors.colorScheme, scheme)
      ? existingColors
      : { ...existingColors, colorScheme: scheme },
  };
}

export function createColorSchemeContainer(
  data: BarChartHorizontalDataV2,
  defaultCustomColors: CustomThemeSpecApplied
): ColorSchemeContainer {
  const container = createColorSchemeContainerWithPalette(defaultCustomColors);
  const scheme = makeFullColorScheme(
    container,
    data.barsSpec.uniqueColorKeys,
    data.colorConfig.defaultToSingleColor,
    data.colorConfig.colorableDimensionLabel
  );
  container.colorScheme = scheme;

  return container;
}

export function createBarChartHorizontalData<T extends ChartDataUnknown>(
  chartData: T,
  svgWidth: number
): BarChartHorizontalDataV2 {
  const barOrientation = Orientation.horizontal;
  const settings = chartData.outputSettings;
  const defaultTextStyleLabels = getDefaultTextStyle(settings.chart.labelSize);

  let useNarrowFormat = settings.denseHorizontalBarsMode;

  const {
    dataDimensions,
    nonPrimaryLabelsByDimension,
    nonPrimaryDimensions,
    legendDimension,
    legendDimensionLabel,
  } = chartData.prepareDimensionsAndLabelsBarChart(
    barOrientation,
    fixedNonPrimaryDimensionsValid(
      chartData.nonPrimaryDataDimensions,
      settings.fixedDimensionOrder ?? [],
      false
    )
      ? settings.fixedDimensionOrder ?? undefined
      : undefined
  );

  /** Custom labels, when available */
  const nonPrimaryLabelsByDimensionCustom = nonPrimaryLabelsByDimension.map(
    (d) => {
      const customLabels = settings.customLabels?.[d.dimension];
      if (!defined(customLabels)) {
        return d;
      }
      return {
        ...d,
        labelsSorted: d.labelsSorted.map((l) => customLabels[l] ?? l),
      };
    }
  );

  const labelLayersUnpositioned = nonPrimaryLabelsByDimensionCustom
    .filter((l) => l.dimension !== legendDimension)
    .map((l) => {
      return {
        header: chartData.dimensionHeader(l.dimension),
        dimension: l.dimension as Dimension,
        labels: l.labelsSorted,
        formattedLabels: l.labelsSorted.map((rawLabel) =>
          chartData.dimensionFormatter(l.dimension, rawLabel)
        ),
      };
    });

  const labelHeadersWidth = labelLayersUnpositioned.reduce(
    (acc, layer) =>
      acc +
      (!defined(layer.header)
        ? 0
        : new MultilineText(
            layer.header,
            defaultDimensionLabelTextStyle(settings.chart.labelSize),
            {
              desiredMaxWidth: 900,
            }
          ).height),
    0
  );
  const labelsMaxWidth = svgWidth / 3 - labelHeadersWidth;
  const labelLayers = LayeredLabelsHorizontalBars.fromLabelLayers(
    labelLayersUnpositioned,
    defaultTextStyleLabels,
    labelsMaxWidth,
    {
      labelAnchorPadding: GROUPED_LABELS_HORIZONTAL_BARS_PADDING_RIGHT,
      denseHorizontalBarsMode: useNarrowFormat,
    }
  );

  const numberOfBars = calculateNumberOfBarsFromLayers(
    nonPrimaryLabelsByDimensionCustom.find(
      (l) => l.dimension === legendDimension
    )?.labelsSorted,
    labelLayers.layers()
  );
  const colorLabelTextsOriginal = defined(legendDimension)
    ? nonPrimaryLabelsByDimension.find((l) => l.dimension === legendDimension)
        ?.labelsSorted
    : undefined;

  const colorLabelTextsCustom = defined(legendDimension)
    ? nonPrimaryLabelsByDimensionCustom.find(
        (l) => l.dimension === legendDimension
      )?.labelsSorted
    : undefined;

  const minBarPixels = useNarrowFormat
    ? NARROW_FORMAT_MIN_BAR_PIXELS
    : MIN_PIXELS_PER_BAR_DEFAULT;

  const layersExtended = labelLayers.extendedLayers();
  const chartInnerHeight = Math.max(
    svgWidth / GOLDEN_RATIO,
    // If no layer, use default value. No layer means we have a single bar.
    // This will never happen in practice because we default to bar charts with vertical bars,
    // but it can happen in tests.
    layersExtended.length === 0
      ? svgWidth / GOLDEN_RATIO
      : getLayerHeight(
          layersExtended[0],
          layersExtended.slice(1),
          minBarPixels,
          useNarrowFormat,
          colorLabelTextsCustom
        )
  );

  const legendHeader = defined(legendDimension)
    ? chartData.dimensionHeader(legendDimension)
    : undefined;
  const useTopTicksAxis = chartInnerHeight > 500;
  const chartSource = chartData.source(
    chartInnerHeight,
    settings.customSourceTextSize
  );
  const horizontalDimensions = calculateBarChartHorizontalWidths(
    svgWidth,
    chartSource,
    (labelLayers.totalWidth ?? 0) + labelHeadersWidth
  );
  const legend = calculateLegend(
    barOrientation,
    chartInnerHeight,
    legendHeader,
    colorLabelTextsOriginal?.map((l) => {
      if (defined(legendDimension)) {
        const formattedValue = chartData.dimensionFormatter(legendDimension, l);
        return {
          colorKey: formattedValue,
          fullOriginalLabel: formattedValue,
          customLabel: settings.customLabels?.[legendDimension]?.[l],
        };
      }
      return {
        colorKey: l,
        fullOriginalLabel: l,
      };
    }) ?? [],
    dataDimensions.length,
    horizontalDimensions.boundedWidth,
    chartInnerHeight,
    settings.chart.labelSize,
    defaultTextStyleLabels
  );

  // Make band scales that can work for any number of groupings
  const maxRangeAxisScreenValue = horizontalDimensions.boundedWidth;
  const maxDomainAxisScreenValue = chartInnerHeight;
  const legendDim = nonPrimaryLabelsByDimensionCustom.find(
    (l) => l.dimension === legendDimension
  );
  const isTallChart =
    maxDomainAxisScreenValue > 600 ||
    maxDomainAxisScreenValue > maxRangeAxisScreenValue;
  const bandScales: BandScalesByDimension = makeBandScales(
    labelLayersUnpositioned,
    defined(legendDim)
      ? { dimension: legendDim.dimension, labels: legendDim.labelsSorted }
      : undefined,
    maxDomainAxisScreenValue,
    isTallChart,
    numberOfBars,
    useNarrowFormat,
    useNarrowFormat ? minBarPixels : undefined
  );

  // Ranges & ticks
  const xTicksStyle = defaultTicksStyle(settings.chart.labelSize);
  const { axisOffset, rangeScale, xTicksContainer } = getAxesInfo(
    chartData,
    maxRangeAxisScreenValue,
    xTicksStyle
  );

  const innerMostDimension = last(labelLayers.layers())?.dimension as
    | Dimension
    | undefined;
  const innerMostDimensionLabel = defined(innerMostDimension)
    ? chartData.dimensionToLabel(innerMostDimension)
    : undefined;

  // Labels & bars
  const rawLabels = calculateGroupedLabels(labelLayers, bandScales);
  const bars = calculateGroupedBarsSpec(
    chartData.rows,
    nonPrimaryDimensions.slice(),
    innerMostDimension,
    innerMostDimensionLabel,
    legendDimension,
    legendDimensionLabel,
    bandScales,
    (row, round) => rangeScale(round?.(row.range()) ?? row.range()),
    axisOffset,
    chartData.dimensionFormatter,
    chartData.computedValueOutputSettings,
    settings.customLabels ?? undefined
  );

  const titleRows = chartData.titleRows(
    horizontalDimensions.boundedWidth,
    settings
  );

  const preparedLayeredLabels = labelSpecToTextContainerHorizontalBars(
    chartInnerHeight,
    settings.chart.labelSize,
    _.groupBy(rawLabels, (label) => label.level),
    {
      domainAxisPosition: chartInnerHeight / 2,
      getDimensionHeader: chartData.dimensionHeader.bind(chartData),
    }
  );
  const labelAreas = calculateLabelAreasBarChartHorizontal(
    preparedLayeredLabels.totalWidth,
    colorLabelTextsCustom,
    undefined,
    xTicksStyle,
    defaultTextStyleLabels
  );
  const chartDimensions = calculateBarChartHorizontalDimensions(
    horizontalDimensions,
    labelAreas.labelsLeft?.totalWidth ?? 0,
    chartInnerHeight,
    titleRows,
    chartSource,
    xTicksContainer,
    useTopTicksAxis,
    legend
  );

  const legendStandard =
    defined(legend) && legend.orientation === Orientation.horizontal
      ? legend
      : undefined;

  const colorConfig = calcBarChartColorConfig(
    legendDimension,
    legendDimensionLabel,
    nonPrimaryLabelsByDimension.find((l) => l.dimension === legendDimension)
      ?.labelsSorted,
    innerMostDimension,
    innerMostDimensionLabel,
    nonPrimaryLabelsByDimension.find((l) => l.dimension === innerMostDimension)
      ?.labelsSorted
  );

  const barsSpec = {
    uniqueColorKeys: uniq(bars.map((b) => b.colorKey)),
    bars,
    outputRange: rangeScale.domain().map(rangeScale) as [number, number],
  };

  return {
    title: titleRows,
    liftedDimensions: chartData.liftedDimensions(),
    legendDimension,
    axisOffset: axisOffset,
    barsSpec: barsSpec,
    chartDimensions: chartDimensions,
    colorConfig: colorConfig,
    formatRange: (value: number): string => {
      return chartData.rangeFormatter(value);
    },
    labelAreas: labelAreas,
    labels: preparedLayeredLabels.preparedLabels,
    legendDimensionLabel: legendDimensionLabel,
    legendSide: undefined, // Add value as needed
    legendStandard: legendStandard,
    rangeTicksHorizontalAxis: xTicksContainer,
    settings: settings,
    source: chartSource,
    useTopTicksAxis: useTopTicksAxis,
    bandScales: bandScales,
  };
}

function getAxesInfo<T extends ChartDataUnknown>(
  chartData: T,
  maxRangeAxisScreenValue: number,
  xTicksStyle: TicksStyle
) {
  const settings = chartData.outputSettings;
  const [rangeMin, rangeMax] = chartData.rangeExtent(settings.startFromZero);
  const rangeScaleReversed = d3scl
    .scaleLinear()
    .domain([rangeMin, rangeMax])
    .rangeRound([maxRangeAxisScreenValue, 0])
    .nice();
  const rangeScaleRegular = rangeScaleReversed
    .copy()
    .rangeRound([0, maxRangeAxisScreenValue]);
  const niceRange = rangeScaleRegular.domain();
  const axisOffset = getRangeAxisOffset(
    niceRange[0],
    niceRange[1],
    rangeScaleRegular
  );
  const rangeTicks = rangeScaleRegular.ticks(5);
  const rangeTicksFormatter = chartData.makeTicksRangeFormatter(rangeTicks);
  const rangeTicksHorizontal: TickLabelWithRawValue[] = rangeTicks.map((t) => {
    return {
      value: t,
      text: rangeTicksFormatter(t),
      offset: rangeScaleRegular(t),
    };
  });
  const xTicksContainer = new XTicksContainer(
    chartData.normalizeTicks(rangeTicksHorizontal),
    xTicksStyle
  );
  return { xTicksContainer, rangeScale: rangeScaleRegular, axisOffset };
}

/**
 * Total layer height is basically determined recursively by:
 * num labels in layer *
 *  Max(
 *      greatest size required by any of the labels of this layer,
 *      size required by sublayers
 *  )
 */
function getLayerHeight(
  layer: LabelLayerWithRenderInfo<string>,
  sublayers: LabelLayerWithRenderInfo<string>[],
  minPixelsPerBar: number,
  tightMode: boolean,
  colorLabelTexts?: string[]
): number {
  const currentLayerMaxLabelHeight =
    Math.max(
      ...layer.renderInfo.textContainers.map((t) => t.container.height)
    ) + (tightMode ? 0 : 10); // Add a bit of min margin for the labels

  const currentLayerMax = Math.max(
    currentLayerMaxLabelHeight,
    minPixelsPerBar * 1.5
  );

  if (sublayers.length === 0) {
    const base =
      minPixelsPerBar * 1.5 + currentLayerMax * layer.layer.labels.length;
    // If there's a legend layer, make sure to include the space needed for the bars
    if (defined(colorLabelTexts)) {
      return Math.max(
        base,
        layer.layer.labels.length *
          colorLabelTexts.length *
          minPixelsPerBar *
          1.6
      );
    }
    return base;
  }

  return (
    layer.layer.labels.length *
    Math.max(
      currentLayerMax,
      getLayerHeight(
        sublayers[0],
        sublayers.slice(1),
        minPixelsPerBar,
        tightMode,
        colorLabelTexts
      )
    )
  );
}
