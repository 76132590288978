import { useEffect, useRef, useState } from "react";

import { SvgWrapper } from "./shared/SvgWrapper";
import Bounds from "./shared/Bounds";
import { ChartLegendBottom } from "./shared/ChartLegendCommon";
import { TicksBottom } from "./shared/ticks/TicksBottom";
import { TicksTop } from "./shared/ticks/TicksTop";
import { ChartSourceVertical } from "./shared/ChartSource";
import { LinesVertical } from "./shared/LinesVertical";
import { Position2D } from "../../lib/core/space/position";
import { AxisLineBottom } from "./shared/axes/AxisLineBottom";
import { ChartTitle } from "./shared/ChartTitle";
import { AxisLineLeft } from "./shared/axes/AxisLineLeft";
import {
  BarSpecLowBase,
  BarSpecLowInvalidChoice,
  BarSpecRegular,
} from "../../lib/application/stats/shared/bar_chart/bar_chart_common";
import {
  getDefaultTextStyleChartValueLabel,
  TextStyle,
} from "../../lib/application/stats/shared/core/TextStyle";
import { TextContainerPositioned } from "../../lib/application/stats/shared/core/text_containers";
import { calculateTextWidth } from "../../lib/application/stats/shared/core/text_containers/measure";
import { translatePosition } from "../../lib/application/stats/svg";
import { BarAndLabelProps, BarLabel, BarWithoutLabel } from "./shared/Bar";
import { MainChartDimensions } from "../../lib/application/stats/shared/core/definitions";
import { AxisLineTop } from "./shared/axes/AxisLineTop";
import { useUpdateSvgThumbnail } from "../../lib/application/hooks/thumbnails";
import { RenderPositionedTextLine } from "./shared/Text";
import { DebugBoxes } from "./shared/DebugBoxes";
import {
  SURVEY_INVALID_CHOICE_LABEL,
  SURVEY_LOW_BASE_LABEL,
} from "../../lib/domain/measure/definitions";
import { PrintDummy } from "../print/PrintDummy";
import { ChartDataContainerV2BarHorizontal } from "../../lib/application/state/stats/document-core/_core-shared";
import { defined } from "../../lib/core/defined";
import { DEFAULT_CHART_TEXT_COLOR } from "../../lib/application/stats/shared/core/colors/colors";
import { omit } from "lodash";

export interface Props {
  debug?: boolean;
  cardId: string;
  dataContainer: ChartDataContainerV2BarHorizontal;
  preserveAspectRatio?: string;
}
/**
 * Bar chart container where the range is shown on the horizontal axis.
 */
export function BarChartHorizontalContainerV2(props: Props) {
  const svgContainerRef = useRef<null | SVGSVGElement>(null);
  const handleUpdate = useUpdateSvgThumbnail(svgContainerRef, props.cardId);
  useEffect(() => {
    handleUpdate();
  }, [handleUpdate, props.dataContainer]);

  return (
    <BarChartHorizontalInner {...props} svgContainerRef={svgContainerRef} />
  );
}

/** Inner container, free from hooks */
export function BarChartHorizontalInner(
  props: Props & {
    svgContainerRef: React.MutableRefObject<SVGSVGElement | null>;
  }
) {
  const chartData = props.dataContainer.data;
  const svgContainerRef = props.svgContainerRef;

  const [focusedBar, setFocusedBar] = useState<string>();

  const dimensions = chartData.chartDimensions;
  const { main: dims, title: titleDims, source } = dimensions;
  const legendBottom = chartData.legendStandard;

  const labelContainers = chartData.labels;

  const axisOffset = chartData.axisOffset;
  const xTicksContainer = chartData.rangeTicksHorizontalAxis;

  const renderableBars: BarAndLabelProps[] = getRenderableBars(
    props.dataContainer,
    focusedBar,
    setFocusedBar,
    dims
  );

  const colorsContainer = props.dataContainer.colorSchemeContainer;
  const settings = chartData.settings;

  return (
    <div className="svg-chart-container">
      <SvgWrapper
        cardId={props.cardId}
        width={dims.fullWidth}
        height={dims.fullHeight}
        containerRef={svgContainerRef}
        preserveAspectRatio={props.preserveAspectRatio}
      >
        {props.debug && <DebugBoxes dimensions={dimensions}></DebugBoxes>}
        <ChartTitle
          debug={props.debug}
          position={titleDims.position}
          titleRowSet={chartData.title}
          fontColor={colorsContainer.customHeadersColor}
        />
        <Bounds dims={dims}>
          <LinesVertical
            dims={dims}
            excludeX={axisOffset}
            ticksContainer={xTicksContainer}
            strokeColor={colorsContainer.customGridLinesColor}
            strokeDashArray={settings.gridLinesXStyle}
          />
        </Bounds>
        {settings.showXAxis && (
          <AxisLineLeft
            dims={dims}
            customStrokeColor={colorsContainer.customAxesColor}
            verticalAxisOffset={axisOffset}
          ></AxisLineLeft>
        )}
        {chartData.useTopTicksAxis && (
          <>
            <TicksTop
              fontColor={colorsContainer.customLabelsColor}
              dims={dims}
              ticksContainer={xTicksContainer}
              hideTickLabels={settings.showTicksYAxis === false}
              customTickStrokeColor={
                settings.showTicksYAxis === false
                  ? null
                  : colorsContainer.customAxesColor
              }
            />
            {settings.showYAxis && (
              <AxisLineTop
                dims={dims}
                customStrokeColor={colorsContainer.customAxesColor}
              ></AxisLineTop>
            )}
          </>
        )}
        {settings.showYAxis && (
          <AxisLineBottom
            dims={dims}
            customStrokeColor={colorsContainer.customAxesColor}
          ></AxisLineBottom>
        )}

        <Bounds dims={dims}>
          {props.debug &&
            chartData.bandScales &&
            chartData.bandScales.map((bandScale, i) => {
              const s = bandScale.scale;
              const scaleLocal = (label: string) => {
                let offset = 0;
                for (let scaleCount = 0; scaleCount < i; scaleCount++) {
                  const scale = chartData.bandScales![scaleCount];
                  const prevBandStart = scale.scale(scale.scale.domain()[0])!;
                  offset += prevBandStart;
                }

                return offset + s!(label)!;
              };

              const rangeLocal = () => {
                let offset = 0;
                for (let scaleCount = 0; scaleCount < i; scaleCount++) {
                  const scale = chartData.bandScales![scaleCount];
                  offset += scale.scale(scale.scale.domain()[0])!;
                }

                return offset;
              };

              return bandScale.scale.domain().map((label, di) => {
                return (
                  <>
                    <rect
                      key={label}
                      x={i * 14}
                      y={scaleLocal(label)}
                      width={10}
                      height={bandScale.scale.bandwidth()}
                      fill="rgba(0,0,0,0.9)"
                    ></rect>
                    {di === 0 && (
                      <rect
                        key={label + "-outer-padding-start"}
                        x={i * 14}
                        y={rangeLocal()}
                        width={10}
                        height={scaleLocal(label) - rangeLocal()}
                        fill="rgba(0,0,255,0.9)"
                      ></rect>
                    )}

                    {di === s.domain().length - 1 && (
                      <rect
                        key={label + "-outer-padding-end"}
                        x={i * 14}
                        y={
                          (scaleLocal(label) ?? 0) + bandScale.scale.bandwidth()
                        }
                        width={10}
                        height={s.step() * s.paddingOuter()}
                        fill="rgba(0,0,255,0.9)"
                      ></rect>
                    )}

                    {di !== s.domain().length - 1 && (
                      <rect
                        key={label + "-padding"}
                        x={i * 14}
                        y={
                          (scaleLocal(label) ?? 0) + bandScale.scale.bandwidth()
                        }
                        width={10}
                        height={s.step() - s.bandwidth()}
                        fill="rgba(255,0,0,0.9)"
                      ></rect>
                    )}
                  </>
                );
              });
            })}

          {props.debug && (
            <g style={{ opacity: 0.3 }}>
              <>
                {renderableBars.map((bar) => {
                  const b = omit(bar, "key");
                  return (
                    <BarWithoutLabel {...b} key={bar.key}></BarWithoutLabel>
                  );
                })}
                {renderableBars.map((bar) => {
                  const b = omit(bar, "key");
                  return (
                    <BarLabel
                      {...b}
                      key={bar.key}
                      fontColor={
                        colorsContainer.customLabelsColor ??
                        DEFAULT_CHART_TEXT_COLOR
                      }
                    ></BarLabel>
                  );
                })}
              </>
            </g>
          )}
          {!props.debug && (
            <>
              {renderableBars.map((bar) => {
                const b = omit(bar, "key");
                return <BarWithoutLabel {...b} key={bar.key}></BarWithoutLabel>;
              })}
              {renderableBars.map((bar) => {
                const b = omit(bar, "key");
                return (
                  <BarLabel
                    {...b}
                    key={bar.key}
                    fontColor={
                      colorsContainer.customLabelsColor ??
                      DEFAULT_CHART_TEXT_COLOR
                    }
                  ></BarLabel>
                );
              })}
            </>
          )}
        </Bounds>

        <ChartLabelsLeftMulti
          fontColor={
            colorsContainer.customLabelsColor ?? DEFAULT_CHART_TEXT_COLOR
          }
          position={{
            x: 0,
            y: dims.marginTop,
          }}
          textContainers={labelContainers}
        ></ChartLabelsLeftMulti>
        <TicksBottom
          hideTickLabels={settings.showTicksYAxis === false}
          customTickStrokeColor={
            settings.showTicksYAxis === false
              ? null
              : colorsContainer.customAxesColor
          }
          dims={dims}
          ticksContainer={xTicksContainer}
        />
        {legendBottom && (
          <ChartLegendBottom
            fontColor={
              colorsContainer.customLabelsColor ?? DEFAULT_CHART_TEXT_COLOR
            }
            colorScheme={colorsContainer.colorScheme}
            legendPosition={{
              x: dims.marginLeft,
              y:
                dims.boundedHeight +
                dims.marginTop +
                xTicksContainer.totalHeight,
            }}
            legend={legendBottom}
          ></ChartLegendBottom>
        )}
        <ChartSourceVertical
          fontColor={colorsContainer.customLabelsColor}
          position={source.position}
          source={chartData.source}
        ></ChartSourceVertical>
      </SvgWrapper>
      <PrintDummy></PrintDummy>
    </div>
  );
}

function getRenderableBars(
  dataContainer: ChartDataContainerV2BarHorizontal,
  focusedBar: string | undefined,
  setFocusedBar: (bar: string | undefined) => void,
  dims: MainChartDimensions
): BarAndLabelProps[] {
  const data = dataContainer.data;
  const axisOffset = data.axisOffset;
  const fullColorScheme = dataContainer.colorSchemeContainer.colorScheme;
  const barsSpec = data.barsSpec;
  const settings = data.settings;
  return barsSpec.bars.map((bar) => {
    const key = Object.values(bar).join("-");
    const labelStyle = getDefaultTextStyleChartValueLabel({
      desiredSize: settings.chart.labelSize,
      forcedSize: settings.chart.valueLabelSize,
      maxPixelHeight: bar.width,
    });
    const barFocused = key === focusedBar;

    return {
      onMouseEnter: () => setFocusedBar(key),
      onMouseLeave: () => setFocusedBar(undefined),
      focused: barFocused,
      key: key,
      bar: bar,
      renderSettingsInvalidChoice: (b: BarSpecLowInvalidChoice) =>
        missingValueBarSettings(
          b,
          axisOffset,
          dims,
          key,
          SURVEY_INVALID_CHOICE_LABEL,
          labelStyle
        ),
      renderSettingsLowBase: (b: BarSpecLowBase) =>
        missingValueBarSettings(
          b,
          axisOffset,
          dims,
          key,
          SURVEY_LOW_BASE_LABEL,
          labelStyle
        ),
      renderSettingsRegular: (b: BarSpecRegular) => {
        const rectHeight = b.width;
        const rectWidth = b.heightFromAxis;
        const flip = b.flip && !settings.forceLabelsOutsideBars;
        const commonProps = {
          key,
          className: `dataDomain=${b.domain}, dataRange=${b.range}, domainAxisOffset=${b.domainAxisOffset}`,
          x: flip ? axisOffset + rectWidth : axisOffset + 0.5,
          y: b.domainAxisOffset,
          height: rectHeight,
          width: Math.abs(rectWidth),
          fill: fullColorScheme[b.colorKey],
        } as const;
        const label = defined(b.format)
          ? b.format(b.range.toString())
          : data.formatRange(b.range);
        const labelPositioning = calculateValueLabelXPos(
          { extentFromAxis: b.heightFromAxis, x: commonProps.x },
          flip,
          settings.forceLabelsOutsideBars,
          dims.boundedWidth,
          label,
          labelStyle
        );

        const labelProps = {
          textAnchor: flip ? "right" : "left",
          y: b.domainAxisOffset + b.width / 2,
          dominantBaseline: "middle",
          x: labelPositioning.position,
          style: labelStyle.svgFontAttrs(),
        };
        return {
          bar: commonProps,
          label:
            settings.showLabels || barFocused
              ? {
                  elementProps: labelProps,
                  text: label,
                  shadow: labelPositioning.showLabelOverBar,
                }
              : undefined,
        };
      },
    };
  });
}

function missingValueBarSettings(
  b: BarSpecLowBase | BarSpecLowInvalidChoice,
  axisOffset: number,
  dims: MainChartDimensions,
  key: string,
  label: string,
  labelStyle: TextStyle
) {
  const labelPositioningNoValueLabel = calculateValueLabelXPos(
    { extentFromAxis: 0, x: axisOffset },
    false,
    false,
    dims.boundedWidth,
    label,
    labelStyle
  );

  return {
    bar: { key },
    label: {
      elementProps: {
        x: labelPositioningNoValueLabel.position,
        y: b.domainAxisOffset + b.width / 2,
        textAnchor: "left",
      },
    },
  };
}

function calculateValueLabelXPos(
  sizeAttributes: { x: number; extentFromAxis: number },
  flipBar: boolean,
  forbidFlip: boolean,
  boundedWidth: number,
  label: string,
  labelStyle: TextStyle
): { position: number; showLabelOverBar: boolean } {
  const labelWidth = calculateTextWidth(label, labelStyle);
  const barCanHoldLabel = labelWidth < Math.abs(sizeAttributes.extentFromAxis);
  const labelOffset = 5;
  if (flipBar) {
    const labelTooCloseToEdge =
      sizeAttributes.x - labelWidth < boundedWidth * 0.05;
    return {
      position:
        sizeAttributes.x +
        (labelTooCloseToEdge ? labelOffset : -(labelWidth + labelOffset)),
      showLabelOverBar: labelTooCloseToEdge && barCanHoldLabel,
    };
  }

  const labelTooCloseToEdge =
    !forbidFlip &&
    sizeAttributes.x + sizeAttributes.extentFromAxis + labelWidth >
      boundedWidth * 0.95;
  return {
    position:
      sizeAttributes.x +
      sizeAttributes.extentFromAxis +
      (labelTooCloseToEdge ? -(labelWidth + labelOffset) : labelOffset),
    showLabelOverBar: labelTooCloseToEdge && barCanHoldLabel,
  };
}

function ChartLabelsLeftMulti(props: {
  fontColor?: string;
  position: Position2D;
  textContainers: TextContainerPositioned[];
}) {
  const commonOptions = {
    className: "mixed-alpha-numeric",
    lengthAdjust: "spacingAndGlyphs",
    dominantBaseline: "middle",
  };
  return (
    <g transform={translatePosition(props.position)}>
      {props.textContainers.map((item) => {
        return item.lines.map((line, i) => {
          return (
            <RenderPositionedTextLine
              key={line.text + i}
              {...commonOptions}
              fontColor={props.fontColor}
              anchor={item.anchor}
              line={line}
              textStyle={item.textStyle}
            ></RenderPositionedTextLine>
          );
        });
      })}
    </g>
  );
}
