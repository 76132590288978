import { useCallback, useContext, useState } from "react";
import { defined } from "../lib/core/defined";
import { Button } from "./Button";

import "./FileDrop.scss";
import { AppMessagesContext } from "../lib/application/contexts";

interface Props {
  accept?: string;
  onSubmit: (f: File) => void;
  multi: false;
  disabled?: boolean;
}
interface PropsMultiUpload {
  accept?: string;
  onSubmitMulti: (f: File[]) => void;
  multi: true;
  disabled?: boolean;
}
export function FileDrop(props: Props | PropsMultiUpload) {
  const [selectedFileList, setSelectedFileList] = useState<FileList | null>();

  const appMessages = useContext(AppMessagesContext);

  const handleSubmit = useCallback(() => {
    if (!defined(selectedFileList)) {
      appMessages?.add("error", "Ingen fil vald");
      return;
    }

    if (props.multi) {
      props.onSubmitMulti(Array.from(selectedFileList));
    } else {
      props.onSubmit(selectedFileList[0]);
    }
  }, [appMessages, props, selectedFileList]);

  return (
    <div className="infostat-file-drop">
      <input
        disabled={props.disabled}
        accept={props.accept}
        type="file"
        multiple={props.multi}
        onChange={(e) => {
          setSelectedFileList(e.target.files);
        }}
      ></input>
      {defined(selectedFileList) && (
        <Button
          disabled={props.disabled}
          title="Ladda upp"
          onClick={handleSubmit}
        ></Button>
      )}
    </div>
  );
}
