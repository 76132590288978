import { defined } from "../../../../core/defined";
import { LABEL_FONTS, NUMBER_LABEL_FONTS } from "./definitions";

export const LOw_BASE_FONT_COLOR = "#555";

export type FontWeight = "normal" | "bold";
export type FontStyle = "normal" | "italic";

export const CHART_VALUE_LABEL_MAX_SIZE = 16;
export const AVAILABLE_CHART_LABEL_SIZES = [12, 14, 16, 18, 20, 24];
export const DEFAULT_FONT_SIZE_MAIN_HEADER = 18;
export const DEFAULT_FONT_SIZE_UNIT_LABEL = 12;
export const DEFAULT_FONT_SIZE_SMALL_SUBTEXT = 12;
export const DEFAULT_FONT_SIZE_LARGE_SUBTEXT = 14;
export const DEFAULT_FONT_SIZE_LABELS = 12;
export const DEFAULT_FONT_SIZE_SOURCE_TEXT = 10;
export const DEFAULT_TEXT_SIZE_CHART_LABELS = DEFAULT_FONT_SIZE_LABELS;

/**
 * Pixel-based text style
 */
export class TextStyle {
  private _fontWeight: FontWeight;
  private _fontStyle: FontStyle;
  private _fontFamily: string | undefined;

  constructor(
    private _fontSizePixels: number,
    options: {
      weight?: FontWeight;
      style?: FontStyle;
      fontFamily?: string;
    } = {}
  ) {
    this._fontWeight = options.weight ?? "normal";
    this._fontStyle = options.style ?? "normal";
    this._fontFamily = options.fontFamily ?? LABEL_FONTS;
  }

  public serialize(): string {
    return `size:${this._fontSizePixels};weight:${this._fontWeight};style:${
      this._fontStyle
    };family:${this._fontFamily ?? ""}`;
  }

  public fontSize(): number {
    return this._fontSizePixels;
  }
  /**
   * Font size string in pixels
   */
  public fontSizeAttr(): string {
    return this._fontSizePixels + "px";
  }

  public fontWeightAttr(): string {
    return this._fontWeight;
  }

  public fontStyleAttr(): string {
    return this._fontStyle;
  }

  public fontFamilyAttr(): string | undefined {
    return this._fontFamily;
  }

  public withSmallerFont(): TextStyle {
    return new TextStyle(this._fontSizePixels - 2, {
      weight: this._fontWeight,
      style: this._fontStyle,
      fontFamily: this._fontFamily,
    });
  }

  public multiply(multiplier = 1): TextStyle {
    return new TextStyle(this._fontSizePixels * multiplier, {
      weight: this._fontWeight,
      style: this._fontStyle,
      fontFamily: this._fontFamily,
    });
  }

  public svgFontAttrs() {
    return {
      fontSize: this._fontSizePixels,
      fontFamily: this._fontFamily,
      fontStyle: this._fontStyle,
      fontWeight: this._fontWeight,
    };
  }
}

export function getDefaultTextStyle(size?: number): TextStyle {
  return new TextStyle(size ?? DEFAULT_TEXT_SIZE_CHART_LABELS, {
    fontFamily: LABEL_FONTS,
  });
}

export function getDefaultTextStyleNumeric(options?: {
  desiredSize?: number;
  maxPixelHeight?: number;
}): TextStyle {
  const maxPixelHeight = options?.maxPixelHeight;
  const desiredSize = options?.desiredSize ?? 14;
  const size = Math.min(maxPixelHeight ?? desiredSize, desiredSize);
  return new TextStyle(size, { fontFamily: NUMBER_LABEL_FONTS });
}

export function getDefaultTextStyleChartValueLabel(options?: {
  desiredSize?: number;
  maxPixelHeight?: number;
  forcedSize?: number;
}) {
  const maxPixelHeight = options?.maxPixelHeight;
  const desiredSize = Math.min(
    options?.desiredSize ?? DEFAULT_TEXT_SIZE_CHART_LABELS,
    CHART_VALUE_LABEL_MAX_SIZE
  );
  const size =
    options?.forcedSize ?? Math.min(maxPixelHeight ?? desiredSize, desiredSize);
  return new TextStyle(Math.max(size, 10), { fontFamily: LABEL_FONTS });
}

/**
 * Dimension labels (category headers) are used as headers for categories (e.g. Gender) and
 * are bigger (+2) than base labels
 */
export function defaultDimensionLabelTextStyle(baseLabelSize?: number) {
  return new TextStyle(defined(baseLabelSize) ? baseLabelSize + 2 : 14, {
    weight: "bold",
  });
}
