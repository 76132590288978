import * as d3scl from "d3-scale";
import * as _ from "lodash";

import { defined } from "../../../../core/defined";
import { product } from "../../../../core/product";
import { ColorKey, Dimension, Orientation } from "../core/definitions";
import { MAX_LABEL_WIDTH } from "../core/text_containers/measure";
import {
  defaultDimensionLabelTextStyle,
  getDefaultTextStyle,
  TextStyle,
} from "../core/TextStyle";
import { calculateBottomLegend, calculateSideLegend } from "../labels";
import { Sign, sign } from "../../../../core/sign";
import {
  MultilineText,
  TextContainerPositioned,
} from "../core/text_containers";
import { Position2D } from "../../../../core/space/position";
import { BandScalesByDimension, makeBandScales } from "./bandScales";
import { LabelLayer } from "./labels/definitions";
import { LayeredLabelsVerticalBars } from "./labels/LayeredLabelsVerticalBars";
import {
  ColorConfig,
  getSpecialColorScheme,
} from "../core/colors/colorSchemes";
import { getSpecialColorSchemeKeyByDimensionAndLabels } from "../charts_common";
import { isLast } from "../../../../core/last";
import { DimensionAndLabels } from "../dimensions";

export interface AllBarsSpec {
  uniqueColorKeys: string[];
  bars: BarSpec[];
  outputRange: [number, number];
}

export interface LegendLabel extends ColorKey {
  fullOriginalLabel: string;
  customLabel?: string;
}

export interface BarSpecCommon extends ColorKey {
  key: string;
  /**
   * Debug values: dimensions for row that bar is based on
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dimensions: (d: string) => any;
  /**
   * Debug value
   */
  domain: string;

  domainAxisOffset: number;
  width: number;
}

export type BarSpecRegular = BarSpecCommon & {
  type: "regular";
  range: number;
  /** Special formatting used for computed values */
  format?: (value: string) => string;

  /**
   * Flip over axis, like when a bar is negative
   */
  flip: boolean;
  /**
   * Signed value indicating the height and direction from axis
   */
  heightFromAxis: number;
};

export type BarSpecLowBase = BarSpecCommon & {
  type: "low_base";
};

export type BarSpecLowInvalidChoice = BarSpecCommon & {
  type: "invalid_choice";
};

export type BarSpec = BarSpecRegular | BarSpecLowBase | BarSpecLowInvalidChoice;

/**
 *  _________________________________________________
 *     |
 *     |
 *     ||
 *    1v|_______________
 *      |               | ^
 *      |               | |
 *      |               | 2
 *      |               | |
 *      |_______________| v
 *      |
 *      |
 *
 *
 * |
 * |        <-- 2 -->
 * |         _______
 * |        |       |
 * |        |       |
 * |        |       |
 * |        |       |
 * |________|_______|______
 * ------->1
 *
 */
export type LabelSpec = {
  level: number;
  dimension: string;

  /**
   * 1: Offset from the axis until where the edge of the bar is.
   * The width of the bar is the bandwith below.
   */
  domainAxisOffset: number;

  /**
   * 2: Width of current band/bar
   */
  bandwidth: number;
};

export type LabelSpecWithTextContainer = LabelSpec & {
  textContainer: MultilineText;
};

export type GroupedLabelsWithTextContainer = {
  [level: number]: LabelSpecWithTextContainer[];
};

export type GroupedLabelsPositioned = {
  [level: number]: TextContainerPositioned[];
};

export type NestedArray<First, Second> = [
  First,
  NestedArray<First, Second>[] | Array<Second>
];

export function calculateNumberOfBarsFromLayers(
  legendLabels: string[] | undefined,
  layers: Omit<LabelLayer, "formattedLabels">[]
) {
  if (layers.length === 0) {
    return Math.max(legendLabels?.length ?? 1, 1);
  }
  return (
    product(layers.map((l) => Math.max(1, l.labels.length))) *
    (legendLabels?.length ?? 1)
  );
}

/**
 * Calculate the pixel offset of the range axis.
 * a) Range includes 0: offset axis to 0
 * b) Range is all negative: offset axis to rangeMax (the least negative number)
 * c) Range is all positive: don't offset axis
 */
export function getRangeAxisOffset(
  rangeInputMin: number,
  rangeInputMax: number,
  rangeScale: d3scl.ScaleLinear<number, number>
): number {
  const rangeContainsZero = sign(rangeInputMin) !== sign(rangeInputMax);
  if (rangeContainsZero) {
    return rangeScale(0);
  }

  if (
    sign(rangeInputMin) === Sign.negative &&
    sign(rangeInputMax) === Sign.negative
  ) {
    return rangeScale(rangeInputMax);
  }

  return 0;
}

export function calculateLegend(
  barOrientation: Orientation,
  chartInnerHeight: number,
  legendHeader: string | undefined,
  colorLabels: LegendLabel[],
  numTotalDimensions: number,
  maxWidth: number,
  maxSideLegendHeight: number | undefined,
  baseLabelTextSize: number,
  textStyle: TextStyle
) {
  const moveLegendToSide = numTotalDimensions > 3;

  const legendOrientation = legendPlacementOrientation(
    barOrientation,
    moveLegendToSide
  );
  const legend =
    legendOrientation === Orientation.horizontal
      ? calculateBottomLegend(
          maxWidth,
          legendHeader,
          colorLabels ?? [],
          baseLabelTextSize,
          textStyle
        )
      : calculateSideLegend(
          chartInnerHeight,
          maxSideLegendHeight ?? maxWidth / 2,
          legendHeader,
          colorLabels ?? [],
          baseLabelTextSize,
          textStyle
        );
  return legend;
}

export function makeDefaultMultilineLabelVerticalBars(
  s: string,
  textStyle: TextStyle,
  desiredMaxWidth?: number
): MultilineText {
  return new MultilineText(s, textStyle, {
    desiredMaxWidth: desiredMaxWidth ?? MAX_LABEL_WIDTH,
  });
}

export function makeDefaultMultilineLabelHorizontalBars(
  s: string,
  textStyle: TextStyle
): MultilineText {
  return new MultilineText(s, textStyle, {
    desiredMaxWidth: MAX_LABEL_WIDTH,
    anchorPadding: 10,
  });
}

/**
 * Take grouped labels and position them for display
 */
export function labelSpecToTextContainerVerticalBars(
  innerChartWidth: number,
  baseLabelTextSize: number,
  groupedLabelsRaw: GroupedLabelsWithTextContainer,
  dimensionLabels?: {
    domainAxisPosition: number;
    getDimensionHeader: (dimension: string) => string | undefined;
  }
): { preparedLabels: TextContainerPositioned[]; totalHeight: number } {
  const dimensionLabelPaddingTop = 5;
  const levelGroups = Object.entries(groupedLabelsRaw).map(([level, specs]) => {
    const labels = specs.map((l) => {
      const positionX = l.domainAxisOffset + l.bandwidth * 0.5;
      return {
        multilineLabel: l.textContainer,
        positionX,
      };
    });
    const maxWidth = _.max(labels.map((l) => l.multilineLabel.maxWidth));
    return { level, specs, labels, maxWidth, dimension: specs[0].dimension };
  });

  // Start from the innermost level and incrementally gather offsets
  const sortedLevelGroups = _.sortBy(levelGroups, (g) => -g.level);
  let totalHeight = 0;
  let yOffset =
    _.max(
      sortedLevelGroups[0]?.labels.map((l) => l.multilineLabel.lineHeight)
    ) ?? 0;
  const containers: TextContainerPositioned[] = [];
  for (const group of sortedLevelGroups) {
    const containersInner: TextContainerPositioned[] = [];
    for (const label of group.labels) {
      const position: Position2D = { y: yOffset, x: label.positionX };
      containersInner.push(
        new TextContainerPositioned(
          position,
          label.multilineLabel.anchor ?? "middle",
          "top",
          label.multilineLabel,
          label.multilineLabel.style,
          { paddingTop: 0, rotation: label.multilineLabel.rotation }
        )
      );
    }

    const maxHeight =
      _.max(group.labels.map((l) => l.multilineLabel.height)) ?? 0;
    yOffset += maxHeight;
    totalHeight += _.max(containersInner.map((c) => c.height)) ?? 0;

    // Add in labels for the dimension
    if (defined(dimensionLabels)) {
      const dimensionLabel = dimensionLabels?.getDimensionHeader(
        group.dimension
      );
      if (defined(dimensionLabel)) {
        const multilineLabel = new MultilineText(
          dimensionLabel,
          defaultDimensionLabelTextStyle(baseLabelTextSize),
          { desiredMaxWidth: innerChartWidth }
        );
        yOffset += dimensionLabelPaddingTop;
        const dimensionLabelContainer = new TextContainerPositioned(
          { y: yOffset, x: dimensionLabels.domainAxisPosition },
          "middle",
          "top",
          multilineLabel,
          multilineLabel.style,
          { paddingTop: 0 }
        );
        containersInner.push(dimensionLabelContainer);
        yOffset += dimensionLabelContainer.height;
        totalHeight += dimensionLabelContainer.height;
      }
    }
    containers.push(...containersInner);
  }
  return { preparedLabels: containers, totalHeight };
}

/**
 * Take grouped labels and position them for display
 *
 * Visualization:
 *
 *  Index:  1                   0
 * ┌────┬──────────┬────┬───────────────┐
 * │    │          │    │               │
 * │    │          │    │               │
 * │    │  Hög     │    │  Män          │
 * │    │          │    │               │
 * │    │          │    │               │
 * │    │          │    │               │
 * │  ▲ │          │    │               │
 * │  │ │          │  ▲ │               │
 * │  │ │          │ N│ │               │
 * │  │ │          │  │ │               │
 * │P │ │          │  │ │               │
 * │  │ │          │  │ │               │
 * │P │ │          │ Ö│ │               │
 * │  │ │          │  │ │               │
 * │U │ │          │  │ │  Kvinnor      │
 * │  │ │          │  │ │               │
 * │R │ │          │ K│ │               │
 * │  │ │          │  │ │               │
 * │G │ │          │  │ │               │
 * │  │ │          │  │ │               │
 * │  │ │  Låg     │    │               │
 * │  │ │          │    │  Samtliga     │
 * │    │          │    │               │
 * │    │          │    │               │
 * │    │          │    │               │
 * └────┴──────────┴────┴───────────────┘
 */
export function labelSpecToTextContainerHorizontalBars(
  chartInnerHeight: number,
  baseLabelTextSize: number,
  groupedLabelsRaw: GroupedLabelsWithTextContainer,
  dimensionLabels?: {
    domainAxisPosition: number;
    getDimensionHeader: (dimension: string) => string | undefined;
  }
): { preparedLabels: TextContainerPositioned[]; totalWidth: number } {
  const levelGroups = Object.entries(groupedLabelsRaw).map(([level, specs]) => {
    const labels = specs.map((l) => ({
      multilineLabel: l.textContainer,
      positionY: l.domainAxisOffset + l.bandwidth * 0.5,
    }));
    const maxWidth = _.max(labels.map((l) => l.multilineLabel.maxWidth));
    return { level, specs, labels, maxWidth, dimension: specs[0].dimension };
  });

  const textContainers: TextContainerPositioned[] = [];

  const columnRightPadding = 10;
  const lastColumnPadding = 0;

  const dimensionLabelTextStyle =
    defaultDimensionLabelTextStyle(baseLabelTextSize);
  let countedWidth = 0;
  for (const group of levelGroups) {
    // If available, add dimension label
    if (defined(dimensionLabels)) {
      const label = dimensionLabels?.getDimensionHeader(group.dimension);
      if (defined(label)) {
        const textContainer = new MultilineText(
          label,
          dimensionLabelTextStyle,
          { desiredMaxWidth: chartInnerHeight }
        );

        // Since this will be rotated 90 degrees relative to other labels, we increment with the height
        countedWidth += textContainer.height;
        textContainers.push(
          new TextContainerPositioned(
            { x: countedWidth, y: dimensionLabels.domainAxisPosition },
            "middle",
            "center",
            textContainer,
            dimensionLabelTextStyle,
            { rotation: -90 }
          )
        );
        countedWidth += columnRightPadding;
      }
    }

    // Then add group labels
    const currentColumnWidth = group.maxWidth ?? 0;
    const groupHalfWidth = currentColumnWidth / 2;

    const columnLabels: TextContainerPositioned[] = [];
    for (const label of group.labels) {
      const xPosition =
        countedWidth +
        (label.multilineLabel.anchor === "middle"
          ? groupHalfWidth
          : label.multilineLabel.anchor === "end"
          ? currentColumnWidth
          : 0);
      const position: Position2D = {
        y: label.positionY,
        x: xPosition,
      };
      columnLabels.push(
        new TextContainerPositioned(
          position,
          label.multilineLabel.anchor ?? "end",
          "center",
          label.multilineLabel,
          label.multilineLabel.style,
          { paddingTop: 0 }
        )
      );
    }
    textContainers.push(...columnLabels);

    const rightPadding = isLast(levelGroups, group)
      ? lastColumnPadding
      : columnRightPadding;
    countedWidth +=
      (_.max(columnLabels.map((c) => c.width)) ?? 0) + rightPadding;
  }

  return {
    preparedLabels: textContainers,
    totalWidth: Math.ceil(countedWidth),
  };
}

export function legendPlacementOrientation(
  barOrientation: Orientation,
  moveLegend: boolean
): Orientation {
  if (barOrientation === Orientation.vertical) {
    return moveLegend ? Orientation.vertical : Orientation.horizontal;
  }
  return Orientation.horizontal;
}

export function makeBandScalesAndLabelLayersVerticalBars(
  nonPrimaryLabelsByDimension: DimensionAndLabels<string>[],
  legendDimension: string | undefined,
  dimensionFormatter: (dimension: string, value: any) => string,
  baseLabelSize: number,
  chartInnerWidth: number
) {
  const labels = nonPrimaryLabelsByDimension
    .filter((l) => l.dimension !== legendDimension)
    .map((l) => {
      return {
        dimension: l.dimension as Dimension,
        labels: l.labelsSorted,
        formattedLabels: l.labelsSorted.map((rawLabel) =>
          dimensionFormatter(l.dimension, rawLabel)
        ),
      };
    });

  const labelLayersUnpositioned = nonPrimaryLabelsByDimension
    .filter((l) => l.dimension !== legendDimension)
    .map((l) => {
      return {
        dimension: l.dimension as Dimension,
        labels: l.labelsSorted,
      };
    });
  const legendDim = nonPrimaryLabelsByDimension.find(
    (l) => l.dimension === legendDimension
  );
  const numberOfBars = calculateNumberOfBarsFromLayers(
    nonPrimaryLabelsByDimension.find((l) => l.dimension === legendDimension)
      ?.labelsSorted,
    labelLayersUnpositioned
  );
  const maxDomainAxisScreenValue = chartInnerWidth;
  const bandScales: BandScalesByDimension = makeBandScales(
    labelLayersUnpositioned,
    defined(legendDim)
      ? { dimension: legendDim.dimension, labels: legendDim.labelsSorted }
      : undefined,
    maxDomainAxisScreenValue,
    false,
    numberOfBars,
    false
  );

  return {
    layeredLabels: LayeredLabelsVerticalBars.fromLabelLayers(
      labels,
      getDefaultTextStyle(baseLabelSize),
      bandScales
    ),
    bandScales,
    numberOfBars,
  };
}

export function calcBarChartColorConfig(
  legendDimension?: string,
  legendDimensionLabel?: string,
  legendLabels?: string[],
  innermostDimension?: string,
  innermostDimensionLabel?: string,
  innermostDimensionLabels?: string[]
): ColorConfig {
  const defaultToSingleColor = !defined(legendDimension);

  if (legendDimension === Dimension.grouping) {
    return {
      defaultToSingleColor: false,
      colorableDimensionLabel: "high-low-range",
    };
  }

  if (defined(legendDimension)) {
    if (
      defined(legendDimensionLabel) &&
      defined(getSpecialColorScheme(legendDimensionLabel))
    ) {
      return {
        defaultToSingleColor: false,
        colorableDimensionLabel: legendDimensionLabel,
      };
    }
    if (defined(legendLabels)) {
      const specialColorSchemeKey =
        getSpecialColorSchemeKeyByDimensionAndLabels(
          legendDimension,
          legendLabels
        );
      if (defined(specialColorSchemeKey)) {
        return {
          defaultToSingleColor,
          colorableDimensionLabel: specialColorSchemeKey,
        };
      }
    }
    return {
      defaultToSingleColor: false,
      colorableDimensionLabel: legendDimensionLabel,
    };
  }

  if (defined(innermostDimension)) {
    if (defined(innermostDimensionLabels)) {
      const specialColorSchemeKey =
        getSpecialColorSchemeKeyByDimensionAndLabels(
          innermostDimension,
          innermostDimensionLabels
        );
      if (defined(specialColorSchemeKey)) {
        return {
          defaultToSingleColor,
          colorableDimensionLabel: specialColorSchemeKey,
        };
      }
    }

    return {
      defaultToSingleColor,
      colorableDimensionLabel: innermostDimensionLabel,
    };
  }

  return {
    defaultToSingleColor,
    colorableDimensionLabel: legendDimensionLabel,
  };
}
