import { registerIcons } from "@fluentui/style-utilities";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "driver.js/dist/driver.css";

import { IS_PROD } from "./config";
import { consoleLogger } from "./lib/infra/logging";
if (!IS_PROD) {
  document.addEventListener("error", function (e) {
    consoleLogger.error("Unhandled error:", e);
    e.preventDefault();
  });
}

const iconMappings: { [key: string]: JSX.Element } = {
  clear: <Icon icon="small-cross"></Icon>,
  up: <Icon icon="caret-up"></Icon>,
  down: <Icon icon="caret-down"></Icon>,
  checkmark: <Icon icon="small-tick"></Icon>,
  chevrondown: <Icon icon="chevron-down"></Icon>,
  chevronright: <Icon icon="chevron-right"></Icon>,
  completed: <Icon icon="tick"></Icon>,
  errorbadge: <Icon icon="error"></Icon>,
  "eye-open": <Icon icon="eye-open"></Icon>,
  "floppy-disk": <Icon icon="floppy-disk"></Icon>,
  "folder-open": <Icon icon="folder-open"></Icon>,
  "folder-close": <Icon icon="folder-close"></Icon>,
  info: <Icon icon="info-sign"></Icon>,
  presentation: <Icon icon="presentation"></Icon>,
  "series-add": <Icon icon="series-add"></Icon>,
  circleshapesolid: <Icon icon="full-circle"></Icon>,
  statuscirclecheckmark: <Icon icon="small-tick"></Icon>,
  circlering: <Icon icon="circle"></Icon>,

  gripperbarvertical: <Icon icon="drag-handle-vertical"></Icon>,
  sortup: <Icon icon="sort-desc"></Icon>,
  page: <Icon icon="document"></Icon>,
};

for (const name of Object.values(IconNames)) {
  iconMappings[name] = <Icon icon={name}></Icon>;
}
registerIcons({
  icons: iconMappings,
});
